// extracted by mini-css-extract-plugin
export var caseContactFormSection = "bp_kw";
export var caseStudyBackground__lineColor = "bp_kt";
export var caseStudyHead__imageWrapper = "bp_kp";
export var caseStudyProjectsSection = "bp_kx";
export var caseStudyQuote__bgLight = "bp_kD";
export var caseStudyQuote__bgRing = "bp_kr";
export var caseStudyQuote__bgRingDark = "bp_kQ";
export var caseStudyQuote__bottomVideo = "bp_kR";
export var caseStudySolution__ring = "bp_kB";
export var caseStudySolution__ringTwo = "bp_kC";
export var caseStudyVideo = "bp_kS";
export var caseStudyVideo__ring = "bp_kG";
export var caseStudy__bgDark = "bp_km";
export var caseStudy__bgLight = "bp_kl";